import React from 'react';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';

import AppBadges from '../badges/AppBadges';
import AngledSection from '../layout/AngledSection';
import DemoEmailForm from '../demo/DemoEmailForm';
import FadeIn from '../transitions/FadeIn';
import ProductPageIntroPhoto from './ProductPageIntroPhoto';
import theme from '../../constants/theme/theme';

const useStyles = makeStyles({
    intro: {
        height: '100vh',
        maxHeight: '800px',
        minHeight: '680px',
        overflow: 'hidden',
        position: 'relative',
        paddingBottom: '130px',
    },
    introBorderWrap: {
        position: 'absolute',
        left: '40vw',
        top: '50vw',
    },
    introBorder: {
        height: '1052px',
        width: '150vw',
        //transform: 'rotate3d(0, 113, 360, -145deg)',
        transform: 'rotate(70deg)',
        position: 'relative',
        boxShadow: '5px 5px 49px inset rgba(50,50,93,.5)',
        borderRadius: '49px'
    },
    introBordersWrapper: {
        //transform: 'rotate(-4deg)',
        background: 'white',
        position: 'absolute',
        left: '0',
        bottom: '0',
        width: '100%',
        '&::after': {
            content: '""',
            height: '42px',
            backgroundColor: "white",
            width: '100vw',
            position: 'absolute',
            bottom: '0',
            left: 'calc(100% - 38px)',
        }
    },
    introBorderWrapperBlock: {
        width: '100%',
        height: '80px',
        background: 'white',
    },
    introBordersLeft: {
        width: '250px',
        listStyleType: 'none',
        position: 'absolute',
        top: '0',
        '& div': {
            height: '42px',
            margin: '0 0 -2px',
            position: 'relative',
            //boxShadow: '5px 5px 8px inset rgba(50, 50, 50, 0.15)',
            '&::after': {
                //boxShadow: '5px 5px 8px inset rgba(50, 50, 50, 0.15)',
                content: '""',
                height: '0',
                width: '0',
                borderTop: '42px solid white',
                borderTopColor: 'inherit',
                borderRight: '42px solid transparent',
                position: 'absolute',
                left: '100%',
                top: '0',
            }
        },
    },
    introBordersRight: {
        width: '180px',
        listStyleType: 'none',
        position: 'absolute',
        top: '-42px',
        right: '0',
        '& div': {
            height: '42px',
            margin: '0 0 -2px',
            position: 'relative',
            //boxShadow: '5px 5px 8px inset rgba(50, 50, 50, 0.15)',
            '&::before': {
                //boxShadow: '5px 5px 8px inset rgba(50, 50, 50, 0.15)',
                content: '""',
                height: '0',
                width: '0',
                borderTop: '42px solid white',
                borderTopColor: 'inherit',
                borderRight: '42px solid transparent',
                position: 'absolute',
                left: '0',
                top: '0',
            }
        },
    },
    introContainer: {
        height: '100%',
    },
    introGrid: {
        height: '100%'
    },
    introGridPhoto: {
        textAlign: 'center',
        //transform: 'scale(1.1)',
        zIndex: '1'
    },
    introGridText: {
        zIndex: '2',
        [theme.breakpoints.down('xs')]: {
            textAlign: 'center'
        }
    }
});

const ProductPage = ({
    appBadges,
    description,
    paletteColor,
    photoSrc,
    photoAlt,
    title,
    googlePlayLink,
    appStoreLink
}) => {
    const classes = useStyles();

    return (
        <AngledSection
            color={theme.palette[paletteColor].main}
            bottomColor={theme.palette[paletteColor].lighter}
            isAngledTop={false}
        >
            <Box pb={8} pt={16} bgcolor={/*`${paletteColor}.light`*/ "white"} className={classes.intro}>
                <Container className={classes.introContainer}>
                    <Grid
                        alignItems="center"
                        className={classes.introGrid}
                        container
                        pt={8}
                        spacing={3}
                    >
                        <Grid item xs={12} sm={6} className={classes.introGridText}>
                            <FadeIn timeout={500}>
                                <Box>
                                    <Box>
                                        <Typography variant="h3" component="h1" gutterBottom color="inherit">
                                            { title }
                                        </Typography>
                                    </Box>
                                    <Box color="primary.dark" pb={4}>
                                        <Typography color="textPrimary">
                                            { description }
                                        </Typography>
                                    </Box>
                                    <DemoEmailForm paletteColor={paletteColor} formLocationName="ProductIntro" />
                                    {
                                        appBadges && (
                                            <AppBadges pt={6} googlePlayLink={googlePlayLink} appStoreLink={appStoreLink} />
                                        )
                                    }
                                </Box>
                            </FadeIn>
                        </Grid>
                        <Grid className={classes.introGridPhoto} item xs={12} sm={6}>
                            <ProductPageIntroPhoto src={photoSrc} alt={photoAlt} />
                        </Grid>
                    </Grid>
                </Container>
                <Box className={classes.introBorderWrap}>
                    <Box
                        className={classes.introBorder}
                        style={{
                            backgroundColor: theme.palette[paletteColor].dark,
                        }}
                    />
                </Box>

            {/* <Box className={classes.introBordersWrapper}>
                <Box
                    className={classes.introBorderWrapperBlock}
                    style={{
                        backgroundColor: theme.palette[paletteColor].light,
                    }}
                />
                <Box className={classes.introBorderWrapperBlock} />
            <div className={classes.introBordersLeft}>
                <div style={{
                    backgroundColor: theme.palette[paletteColor].dark,
                    borderColor: theme.palette[paletteColor].dark,
                }} />
                <div style={{
                    backgroundColor: theme.palette[paletteColor].main,
                    borderColor: theme.palette[paletteColor].main,
                    left: '-24px',
                }} />
                <div style={{
                    backgroundColor: theme.palette.primary.main,
                    borderColor: theme.palette.primary.main,
                    left: '-48px',
                }} />
                <div style={{
                    backgroundColor: theme.palette.primary.light,
                    borderColor: theme.palette.primary.light,
                    left: '-72px',
                }} />
            </div>
            <div className={classes.introBordersRight}>
                <div style={{
                    backgroundColor: theme.palette.primary.main,
                    borderColor: theme.palette[paletteColor].light,
                    right: '-24px',
                }} />
                <div style={{
                    backgroundColor: theme.palette[paletteColor].main,
                    borderColor: theme.palette[paletteColor].light,
                }} />
            </div>
            </Box> */}
            </Box>
        </AngledSection>
    );
};

export default ProductPage;
