import React from 'react';
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied';
import AssignmentTurnedInOutlinedIcon from '@material-ui/icons/AssignmentTurnedInOutlined';
import FlashOnOutlinedIcon from '@material-ui/icons/FlashOnOutlined';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

const DynamicIcon = ({ icon }) => {
    let iconElement = (<HelpOutlineIcon />);

    switch (icon) {
        case 'SentimentVerySatisfiedIcon':
            iconElement = (<SentimentVerySatisfiedIcon />);
            break;
        case 'AssignmentTurnedInOutlinedIcon':
            iconElement = (<AssignmentTurnedInOutlinedIcon />);
            break;
        case 'FlashOnOutlinedIcon':
            iconElement = (<FlashOnOutlinedIcon />);
            break;
        default:
            return null;
    }

    return iconElement;
};

export default DynamicIcon;