import React, { useState, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

import AngledSection from '../layout/AngledSection';
import ContactFormModalTrigger from '../contact/ContactFormModalTrigger';
import FadeInUp from '../transitions/FadeInUp';
import PaletteContext from '../../context/PaletteContext';
import theme from '../../constants/theme/theme';

const ProductPagePricing = ({ benefits, pricingSubtitle, isFixedPrice, product, price, pricePer, pricingCalculatorUrl }) => {
    const paletteColor = React.useContext(PaletteContext);
    
    const [shouldExpandCalculator, setShouldExpandCalculator] = useState(
        typeof window !== 'undefined' && window.innerWidth < 659
    );


    useEffect(() => {
        const handleResize = () => {
            setShouldExpandCalculator(window.innerWidth < 659);
        };

        window.addEventListener('resize', handleResize);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <FadeInUp>
            <AngledSection bottomColor="#fff" color={theme.palette[paletteColor].light}>
                <Box bgcolor={`${paletteColor}.light`} pb={14} pt={10}>
                    {!pricingCalculatorUrl && (
                        <Container maxWidth="sm">
                            <Typography align="center" variant="h3" component="h2">
                                Pricing
                            </Typography>
                            <Box pb={4} pt={4}>
                                <Typography align="center">
                                    {pricingSubtitle}
                                </Typography>
                            </Box>
                            <Card variant="outlined">
                                <Box p={6} textAlign="center">
                                    <Box pb={4}>
                                        <Typography variant="h5" component="p">
                                            {product}
                                        </Typography>
                                    </Box>
                                    <Divider />
                                    <Box pb={4} pt={4} textAlign="center" color={`${paletteColor}.dark`}>
                                        {!isFixedPrice && (
                                            <Typography>
                                                As low as
                                            </Typography>
                                        )}
                                        <Typography variant="h4" component="p" color="inherit" display="inline">
                                            {price}
                                        </Typography>
                                        {' '}
                                        <Typography variant="h6" component="p" color="inherit" display="inline">
                                            {pricePer}
                                        </Typography>
                                    </Box>
                                    <Divider />
                                    <Box pt={4} pb={4}>
                                        {
                                            benefits.map((benefit, i) => (
                                                <Typography gutterBottom={i !== (benefits.length - 1)} key={benefit} color="textSecondary">
                                                    <strong>{benefit}</strong>
                                                </Typography>
                                            ))
                                        }
                                    </Box>
                                    <ContactFormModalTrigger variant="contained" color="primary" size="large">
                                        Contact us to sign up
                                    </ContactFormModalTrigger>
                                </Box>
                            </Card>
                            <Box pt={4}>
                                <Typography align="center" color="textSecondary">
                                    Our products are customized to fit the needs of your business and therefore prices may vary.
                                </Typography>
                            </Box>
                        </Container>
                    )}
                    {pricingCalculatorUrl && (<Container maxWidth="md">
                        <Typography align="center" variant="h3" component="h2">
                            Pricing
                        </Typography>
                        <Box pb={4} pt={4}>
                            <Typography align="center">
                                {pricingSubtitle}
                            </Typography>
                        </Box>
                        <Card variant="outlined" style={{ maxWidth: ''} }>
                            <Box p={6} pt={4} textAlign="center">
                                <Box pb={0} pt={0} textAlign="center" color={`${paletteColor}.dark`}>
                                    <Typography variant="h6" component="p" color="inherit" display="inline">
                                        Calculate your price
                                    </Typography>
                                </Box>
                            </Box>
                            <Box p={6} textAlign="center">
                                <Box
                                    style={{
                                        overflow: 'hidden',
                                        height: shouldExpandCalculator ? '1020px' : '520px',
                                        width: 'calc(100% + 100px)',
                                        margin: '-70px -50px -90px'
                                    }}
                                >
                                    <iframe
                                        src={pricingCalculatorUrl}
                                        style={{
                                            height: shouldExpandCalculator ? '1100px' : '600px',
                                            width: 'calc(100% + 10px)',
                                            marginTop: '-50px',
                                            marginLeft: '-10px',
                                            marginBottom: '-50px',
                                            border: 0,
                                            overflowY: 'hidden'
                                        }}
                                    ></iframe>
                                </Box>
                            </Box>
                        </Card>
                    </Container>

                    )}
                </Box>
            </AngledSection>
        </FadeInUp>
    );
};

export default ProductPagePricing;
