import React from 'react';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import theme from '../../constants/theme/theme';

import FadeInUp from '../transitions/FadeInUp';

const useStyles = makeStyles(theme => ({
    wrapperBox: {
        overflow: 'hidden'
    },
    innerBox: {
        margin: '0 -15px -15px'
    }
}));

const ProductPageFeatures = ({ children, paletteColor, title = 'Features' }) => {
    const classes = useStyles();

    return (
        <>
            <Box className={classes.wrapperBox} bgcolor={`${paletteColor}.light`}>
                <Box className={classes.innerBox} pb={5} pt={12} style={{ boxShadow: `inset 0 0 12px ${theme.palette[paletteColor].lighter}` }}>
                    <FadeInUp>
                        <Container align="center">
                            <Typography variant="h3" component="h2">
                                {title}
                            </Typography>
                        </Container>
                    </FadeInUp>
                </Box>
            </Box>
            {
                React.Children.map(children, (child, i) => {
                    return React.cloneElement(child, {
                        direction: i % 2 === 1 ? 'row-reverse' : 'row',
                        index: i,
                        isAlternate: i % 2 === 0,
                    })
                })
            }
        </>
    );
};

export default ProductPageFeatures;
