import React from 'react';
import Box from '@material-ui/core/Box';
import Fade from '@material-ui/core/Fade';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    image: {
        height: 'auto',
        maxWidth: '100%',
        width: 'auto',
        display: 'inline-block',
        verticalAlign: 'middle'
    },
})

const ProductPageIntroPhoto = ({ src, alt, ...props }) => {
    const [hasLoaded, setHasLoaded] = React.useState(false);
    const classes = useStyles();

    return (
        <Fade in={hasLoaded} timeout={1000}>
            <Box>
                <img
                    {...props}
                    className={classes.image}
                    src={src}
                    alt={alt}
                    ref={(input) => {
                        // onLoad replacement for SSR
                        if (!input) { return; }
                        const img = input;

                        const updateFunc = () => {
                            setHasLoaded(true);
                            img.onload = null;
                        };
                        img.onload = updateFunc;
                        if (img.complete) {
                            updateFunc();
                        }
                    }}
                    />
            </Box>
        </Fade>
    );
};

export default ProductPageIntroPhoto;
