import React from 'react';

import DemoReminder from '../demo/DemoReminder';

const ProductPage = ({
    children,
}) => (
    <>
        { children }
        <DemoReminder />
    </>
);

export default ProductPage;
