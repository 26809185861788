import React from 'react';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

import AngledSection from '../layout/AngledSection';
import DemoCallout from '../demo/DemoCallout';
import FadeInUp from '../transitions/FadeInUp';
import testimonialsList from '../../constants/testimonials/testimonials';

const ProductPageTestimonials = ({ product, paletteColor }) => {
    const [testimonalIndex, setTestimonalIndex] = React.useState(undefined);

    const testimonials = product ? testimonialsList.filter(testimonial => testimonial.products.includes(product)) : testimonialsList;

    React.useEffect(() => {
        setTestimonalIndex(Math.floor(Math.random() * Math.floor(testimonials.length)));
    }, [testimonials]);

    return (
        <FadeInUp>
            <Box>
                {
                    typeof testimonalIndex !== 'undefined' && (
                        <Box pb={12} pt={12}>
                            <Container align="center" maxWidth="sm">
                                <Box color={`${paletteColor}.dark`}>
                                    <Typography color="inherit" gutterBottom variant="h6" component="p">
                                        {`“${testimonials[testimonalIndex].quote}”`}
                                    </Typography>
                                </Box>
                                <Box color={`${paletteColor}.lighter`} pt={2}>
                                    <Typography color="inherit">
                                        <b>{`- ${testimonials[testimonalIndex].author}, ${testimonials[testimonalIndex].company}`}</b>
                                    </Typography>
                                </Box>
                            </Container>
                        </Box>
                    )
                }
                <AngledSection isAngledBottom={false}>
                    <DemoCallout paletteColor={ paletteColor } />
                </AngledSection>
            </Box>
        </FadeInUp>
    );
};

export default ProductPageTestimonials;
