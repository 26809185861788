import React from 'react';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import { makeStyles } from '@material-ui/core/styles';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';

import AngledSection from '../layout/AngledSection';
import FadeInUp from '../transitions/FadeInUp';

const useStyles = makeStyles(theme => ({
    image: {
        borderRadius: '4px',
        margin: '0 auto',
        maxWidth: '100%',
        width: '350px',
        boxShadow: 'rgba(50, 50, 93, 0.25) 0px 50px 30px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px', //'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px',
        [theme.breakpoints.down('sm')]: {
            paddingTop: '10px'
        },
        zIndex: '1',
        position: 'relative'
    },
    wrapperBox: {
        minHeight: '350px'
    }
}))

const ProductPageFeatures = ({ direction, description, index, isAlternate, name, paletteColor, imgSrc, width, angledBottomSection }) => {
    const photoData = useStaticQuery(graphql`
        query {
            osInventory: file(relativePath: { eq: "feature-shots/online-sales/inventory-map.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        layout: CONSTRAINED
                        formats: [AUTO, WEBP, AVIF]
                        placeholder: BLURRED,
                        width: 350
                    )
                }
            }
            osOrders: file(relativePath: { eq: "feature-shots/online-sales/sales-order.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        layout: CONSTRAINED
                        formats: [AUTO, WEBP, AVIF]
                        placeholder: BLURRED,
                        width: 350
                    )
                }
            }
            osPayout: file(relativePath: { eq: "feature-shots/online-sales/integration-payout.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        layout: CONSTRAINED
                        formats: [AUTO, WEBP, AVIF]
                        placeholder: BLURRED,
                        width: 350
                    )
                }
            }
            bcSched: file(relativePath: { eq: "feature-shots/boat-club/customer-reservation.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        layout: CONSTRAINED
                        formats: [AUTO, WEBP, AVIF]
                        placeholder: BLURRED,
                        width: 350
                    )
                }
            }
            brSched: file(relativePath: { eq: "feature-shots/boat-rental/rental-list.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        layout: CONSTRAINED
                        formats: [AUTO, WEBP, AVIF]
                        placeholder: BLURRED,
                        width: 350
                    )
                }
            }
            bcRent: file(relativePath: { eq: "feature-shots/boat-club/inventory-rental.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        layout: CONSTRAINED
                        formats: [AUTO, WEBP, AVIF]
                        placeholder: BLURRED,
                        width: 350
                    )
                }
            }
            bcInspect: file(relativePath: { eq: "feature-shots/boat-club/custom-checklist.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        layout: CONSTRAINED
                        formats: [AUTO, WEBP, AVIF]
                        placeholder: BLURRED,
                        width: 350
                    )
                }
            }
            bcFleet: file(relativePath: { eq: "feature-shots/boat-club/club-schedule.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        layout: CONSTRAINED
                        formats: [AUTO, WEBP, AVIF]
                        placeholder: BLURRED,
                        width: 350
                    )
                }
            }
            bcNotify: file(relativePath: { eq: "feature-shots/boat-club/club-notifications.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        layout: CONSTRAINED
                        formats: [AUTO, WEBP, AVIF]
                        placeholder: BLURRED,
                        width: 350
                    )
                }
            }
            bcClubLoc: file(relativePath: { eq: "feature-shots/boat-club/club-locations.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        layout: CONSTRAINED
                        formats: [AUTO, WEBP, AVIF]
                        placeholder: BLURRED,
                        width: 350
                    )
                }
            }
            dsSched: file(relativePath: { eq: "feature-shots/dry-stack/schedule-launch.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        layout: CONSTRAINED
                        formats: [AUTO, WEBP, AVIF]
                        placeholder: BLURRED,
                        width: 350
                    )
                }
            }
            dsSell: file(relativePath: { eq: "feature-shots/dry-stack/sell-inventory.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        layout: CONSTRAINED
                        formats: [AUTO, WEBP, AVIF]
                        placeholder: BLURRED,
                        width: 350
                    )
                }
            }
            dsRet: file(relativePath: { eq: "feature-shots/dry-stack/retrievals.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        layout: CONSTRAINED
                        formats: [AUTO, WEBP, AVIF]
                        placeholder: BLURRED,
                        width: 350
                    )
                }
            }
            dsLoc: file(relativePath: { eq: "feature-shots/dry-stack/storage-map.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        layout: CONSTRAINED
                        formats: [AUTO, WEBP, AVIF]
                        placeholder: BLURRED,
                        width: 350
                    )
                }
            }
            dsAccountable: file(relativePath: { eq: "feature-shots/dry-stack/launch-stats.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        layout: CONSTRAINED
                        formats: [AUTO, WEBP, AVIF]
                        placeholder: BLURRED,
                        width: 350
                    )
                }
            }
            dsNotify: file(relativePath: { eq: "feature-shots/dry-stack/dry-stack-notifications.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        layout: CONSTRAINED
                        formats: [AUTO, WEBP, AVIF]
                        placeholder: BLURRED,
                        width: 350
                    )
                }
            }
        }
    `);

    const classes = useStyles();
    const Wrapper = isAlternate ? AngledSection : Box;
    return (
        <Wrapper isAngledTop={index !== 0} isAngledBottom={angledBottomSection}>
            <Box
                className={classes.wrapperBox}
                bgcolor={isAlternate ? `${paletteColor}.light` : undefined}
                pb={8}
                pt={8}
            >
                <FadeInUp>
                    <Container>
                        <Grid alignItems="center" container direction={isWidthUp('sm', width) ? direction : 'column'}>
                            <Grid item xs={12} md={6}>
                                <Container align="center">
                                    <Typography variant="h4" component="h3" gutterBottom color="inherit">
                                        { name }
                                    </Typography>
                                    <Typography paragraph color="textPrimary">
                                        { description }
                                    </Typography>
                                </Container>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Container align="center">
                                    <GatsbyImage
                                        alt={`Screenshot of ${name}`}
                                        className={classes.image}
                                        image={getImage(photoData[imgSrc].childImageSharp)}
                                        width="350"
                                    />
                                </Container>
                            </Grid>
                        </Grid>
                    </Container>
                </FadeInUp>
            </Box>
        </Wrapper>
    );
};

export default withWidth()(ProductPageFeatures);
