import React from 'react';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';
import Fade from '@material-ui/core/Fade';
import DynamicIcon from '../icon/DynamicIcon';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';

import { makeStyles } from '@material-ui/core/styles';

import AngledSection from '../layout/AngledSection';
import FadeInUp from '../transitions/FadeInUp';
import PaletteContext from '../../context/PaletteContext';
import TabPanel from '../tabs/TabPanel';
import theme from '../../constants/theme/theme';

const useStyles = makeStyles({
    "@keyframes progress": {
        "0%": {
            transform: "translateY(-100%)"
        },
        "100%": {
            transform: "translateY(0)"
        }
    },
    "@keyframes progressHorizontal": {
        "0%": {
            transform: "translateX(-100%)"
        },
        "100%": {
            transform: "translateX(0)"
        }
    },
    progress: {
        overflow: 'hidden',
        position: 'relative',
    },
    progressBar: {
        animation: '$progress 8000ms linear',
        animationIterationCount: 'infinite',
        height: '100%',
        left: '0',
        position: 'absolute',
        top: '-16px',
        width: '4px',
        [theme.breakpoints.down('sm')]: {
            animation: '$progressHorizontal 8000ms linear',
            height: '4px',
            left: '0',
            top: '0',
            width: '100%',
        },
    },
    tabPanelWrapper: {
        flexGrow: 1,
        display: 'flex',
    },
    tabWrapper: {
        alignItems: 'center',
        display: 'flex',
        height: '100%',
        justifyContent: 'flex-end',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
        },
    },
    tab: {
        fontSize: '20px',
        padding: '24px',
        textTransform: 'none',
        transform: 'scale(1)',
        transition: 'transform 0.3s cubic-bezier(0.45, 0, 0.55, 1)',
        [theme.breakpoints.down('md')]: {
            fontSize: '16px',
        },
        [theme.breakpoints.down('sm')]: {
            padding: '16px 12px',
        },
    },
});

const ProductPageSolutions = ({ solutions, ...props }) => {
    const [activeTabIndex, _setActiveTabIndex] = React.useState(0);
    const [showProgressAnimation, setShowProgressAnimation] = React.useState(true);
    const paletteColor = React.useContext(PaletteContext);
    // Must use ref so that the value is accessible within setInterval
    const activeIndexRef = React.useRef(0);
    const classes = useStyles();
    const intervalRef = React.useRef(null);

    const setActiveTabIndex = (index) => {
        activeIndexRef.current = index;
        _setActiveTabIndex(index);
    };

    const handleChange = (event, newIndex) => {
        // Clear automatic progression
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }
        if (showProgressAnimation) {
            setShowProgressAnimation(false);
        }
        setActiveTabIndex(newIndex);
    };

    React.useEffect(() => {
        intervalRef.current = setInterval(() => {
            setActiveTabIndex(activeIndexRef.current === solutions.length - 1 ? 0 : activeIndexRef.current + 1);
        }, 8000);

        // Comment out for animated highlights display. Causes page to shift when scrolled down on the page if not disabled.
        handleChange(null, 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const TabsWrapper = isWidthUp('md', props.width) ? Container : Box;

    return (
        <FadeInUp threshold={0}>
            <AngledSection
                bottomColor={theme.palette[paletteColor].lighter}
                isAngledTop={false}
                isReversedBottom={true}
            >
                <Box bgcolor={`${paletteColor}.main`} color={`${paletteColor}.light`} pb={12} pt={12}>
                    <Container>
                        <Box color="white" pb={6}>
                            <Typography align="center" color="inherit" variant="h3" component="h2">
                                What you'll get with SpeedyDock
                            </Typography>
                        </Box>
                    </Container>
                    <TabsWrapper>
                        <Grid container={isWidthUp('md', props.width)}>
                            <Grid item md={3}>
                                <Box
                                    className={classes.tabWrapper}
                                    style={{
                                        borderBottom: isWidthUp('md', props.width) ? 0 : `1px solid ${theme.palette[paletteColor].dark}`, 
                                        borderRight: isWidthUp('md', props.width) ? `1px solid ${theme.palette[paletteColor].dark}` : 0,
                                    }}
                                >
                                    <Tabs
                                        aria-label="SpeedyDock provides the following solutions"
                                        orientation={ isWidthUp('md', props.width) ? "vertical" : "horizontal" }
                                        variant="scrollable"
                                        indicatorColor="secondary"
                                        onChange={handleChange}
                                        textColor="inherit"
                                        scrollButtons={isWidthUp('md', props.width) ? "off" : "on"}
                                        value={activeTabIndex}
                                    >
                                        {
                                            solutions.map((solution, index) => (
                                                <Tab
                                                    aria-controls={`solutions-tabpanel-${index}`}
                                                    className={classes.tab}
                                                    id={`solution-tab-${index}`}
                                                    label={solution.title}
                                                    key={solution.title}
                                                    icon={<DynamicIcon icon={solution.icon} />}
                                                    style={{
                                                        opacity: activeTabIndex !== index ? '0.5' : '1',
                                                        transform: activeTabIndex === index ? 'scale(1)' : 'scale(0.85)',
                                                    }}
                                                />
                                            ))
                                        }
                                    </Tabs>
                                </Box>
                            </Grid>
                            <Grid item md={9}>
                                {
                                    solutions.map(({ title, points }, i) => (
                                        <TabPanel
                                            key={title}
                                            display="flex"
                                            id={`solutions-tabpanel-${i}`}
                                            isSelected={i === activeTabIndex}
                                            index={i}
                                            labelledBy={`solution-tab-${i}`}
                                            sm={9}
                                        >
                                            <Box className={classes.progress} textAlign="center" p={3}>
                                                {
                                                    showProgressAnimation && <Box bgcolor={`${paletteColor}.dark`} className={classes.progressBar} />
                                                }
                                                {
                                                    points.map((point, i) => (
                                                        <Fade key={point} in style={{ transitionDelay: showProgressAnimation ? `${i * 1200}ms` : '200ms' }}>
                                                            <Container maxWidth={isWidthUp('md', props.width) ? 'sm' : 'xs'}>
                                                                <Box
                                                                    color={`${paletteColor}.light`}
                                                                    pb={3}
                                                                    pt={3}
                                                                    style={{
                                                                        borderBottom: i !== (points.length - 1) ? `2px solid ${theme.palette[paletteColor].lighter}` : '0',
                                                                    }}
                                                                >
                                                                    <Typography color="inherit" variant="h6" component="h3">{point}</Typography>
                                                                </Box>
                                                            </Container>
                                                        </Fade>
                                                    ))
                                                }
                                            </Box>
                                        </TabPanel>
                                    ))
                                }
                            </Grid>
                        </Grid>
                    </TabsWrapper>
                </Box>
            </AngledSection>
        </FadeInUp>
    );
};

export default withWidth()(ProductPageSolutions);
